import { render, staticRenderFns } from "./PriceForModelWidget.vue?vue&type=template&id=091032f5&scoped=true&"
import script from "./PriceForModelWidget.vue?vue&type=script&lang=js&"
export * from "./PriceForModelWidget.vue?vue&type=script&lang=js&"
import style1 from "./PriceForModelWidget.vue?vue&type=style&index=1&id=091032f5&scoped=true&lang=css&"
import style2 from "./PriceForModelWidget.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "091032f5",
  null
  
)

export default component.exports