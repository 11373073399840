var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-table',{attrs:{"items":_vm.productsList,"fields":_vm.fields,"stacked":"md","show-empty":"","small":"","headVariant":"dark","fixed":"","bordered":""},scopedSlots:_vm._u([{key:"cell(model)",fn:function(row){return [_c('div',{staticClass:"mt-1"},[_c('span',{staticClass:"text-primary",staticStyle:{"cursor":"pointer"},on:{"click":row.toggleDetails}},[_vm._v(_vm._s(row.item.model.slice(0,35)))])])]}},{key:"cell(ooo)",fn:function(ref){
var item = ref.item;
return [_c('global-products-cell-model',{attrs:{"item":item,"seller":'ooo'}})]}},{key:"cell(ip)",fn:function(ref){
var item = ref.item;
return [_c('global-products-cell-model',{attrs:{"item":item,"seller":'ip'}})]}},{key:"cell(vipl)",fn:function(ref){
var item = ref.item;
return [_c('global-products-cell-model',{attrs:{"item":item,"seller":'vipl'}})]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('global-products-level3',{attrs:{"products-list":item.info}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }