<template>
  <div>
  <template v-if="seller=='ooo'">
    <span title="Всего карточек на вб">{{item.stat.ooo}} </span>
    <span title="Низкий рейтинг WB" v-if="item.errorsOOO.lowRating" :class="{'text-danger':item.errorsOOO.lowRating>0, 'text-success':item.errorsOOO.lowRating==0}">  <b-icon icon="star-fill" class="ml-1"></b-icon> {{item.errorsOOO.lowRating}} </span>
    <!--            | <a href="#" @click="getXlsx(item.model, 'ooo')" title="Скачать эксель с ценами"><b-icon icon="file-earmark-excel"></b-icon></a>-->
    <template v-if="item.pricesOOO.length>0 && role=='glava'" >
    <span title="С низкой ценой, доход ниже 100руб, для цветных доход ниже 300руб" v-if="item.errorsOOO.lowPrice" :class="{'text-danger':item.errorsOOO.lowPrice>0, 'text-success':item.errorsOOO.lowPrice==0}">  <b-icon icon="cash" class="ml-1"></b-icon> {{item.errorsOOO.lowPrice}}</span>
    <span title="С высокой ценой, доход выше 650руб" v-if="item.errorsOOO.highPrice" :class="{'text-danger':item.errorsOOO.highPrice>0, 'text-success':item.errorsOOO.highPrice==0}">  <b-icon icon="calculator" class="ml-1"></b-icon> {{item.errorsOOO.highPrice}}</span>
    <span title="Не транслируются остатки" v-if="item.errorsOOO.notQty" :class="{'text-danger':item.errorsOOO.notQty>0, 'text-success':item.errorsOOO.notQty==0}">  <b-icon icon="basket3" class="ml-1"></b-icon> {{item.errorsOOO.notQty}} </span>

    <span v-for="pr of item.pricesOOO.slice(0,3)" :key="`${pr.qty}-${pr.price}`" title="цена(кол-во артикулов с этой ценой)">{{pr.price}}({{pr.qty}}), </span>
    <product-table-v4-price-for-model-widget :seller="'ooo'" :model="item.model" :prices="item.pricesOOO" :key="'model-ooo-' + item.model" />
  </template>
  </template>

    <template v-if="seller=='ip'">
      <span title="Всего карточек на вб">{{item.stat.ip}} </span>
      <span title="Низкий рейтинг WB" v-if="item.errorsIP.lowRating" :class="{'text-danger':item.errorsIP.lowRating>0, 'text-success':item.errorsIP.lowRating==0}">  <b-icon icon="star-fill" class="ml-1"></b-icon> {{item.errorsIP.lowRating}} </span>
      <template v-if="item.pricesIP.length>0 && role=='glava'">
      <span title="С низкой ценой, доход ниже 100руб, для цветных доход ниже 300руб" v-if="item.errorsIP.lowPrice" :class="{'text-danger':item.errorsIP.lowPrice>0, 'text-success':item.errorsIP.lowPrice==0}">  <b-icon icon="cash" class="ml-1"></b-icon> {{item.errorsIP.lowPrice}}  </span>
      <span title="С высокой ценой, доход выше 650руб" v-if="item.errorsIP.highPrice" :class="{'text-danger':item.errorsIP.highPrice>0, 'text-success':item.errorsIP.highPrice==0}">  <b-icon icon="calculator" class="ml-1"></b-icon> {{item.errorsIP.highPrice}}  </span>
      <span title="Не транслируются остатки" v-if="item.errorsIP.notQty" :class="{'text-danger':item.errorsIP.notQty>0, 'text-success':item.errorsIP.notQty==0}">  <b-icon icon="basket3" class="ml-1"></b-icon> {{item.errorsIP.notQty}} </span>


      <span v-for="pr of item.pricesIP.slice(0,3)" :key="`${pr.qty}-${pr.price}`" title="цена(кол-во артикулов с этой ценой)">{{pr.price}}({{pr.qty}}), </span>
       <product-table-v4-price-for-model-widget :seller="'ip'" :model="item.model" :prices="item.pricesIP" :key="'model-ip-' + item.model" />
    </template>

    </template>
    <template v-if="seller=='vipl'">
      <span title="Всего карточек на вб">{{item.stat.vipl}} </span>
      <span title="Низкий рейтинг WB" v-if="item.errorsVIPL.lowRating" :class="{'text-danger':item.errorsVIPL.lowRating>0, 'text-success':item.errorsVIPL.lowRating==0}">  <b-icon icon="star-fill" class="ml-1"></b-icon> {{item.errorsVIPL.lowRating}} </span>
      <template v-if="item.pricesVIPL.length>0 && role=='glava'">
      <span title="С низкой ценой, доход ниже 100руб, для цветных доход ниже 300руб" v-if="item.errorsVIPL.lowPrice>0" :class="{'text-danger':item.errorsVIPL.lowPrice>0, 'text-success':item.errorsVIPL.lowPrice==0}">  <b-icon icon="cash" class="ml-1"></b-icon> {{item.errorsVIPL.lowPrice}}  </span>
      <span title="С высокой ценой, доход выше 650руб" v-if="item.errorsVIPL.highPrice>0" :class="{'text-danger':item.errorsVIPL.highPrice>0, 'text-success':item.errorsVIPL.highPrice==0}"><b-icon icon="calculator" class="ml-1"></b-icon> {{item.errorsVIPL.highPrice}}  </span>
      <span title="Не транслируются остатки" v-if="item.errorsVIPL.notQty" :class="{'text-danger':item.errorsVIPL.notQty>0, 'text-success':item.errorsVIPL.notQty==0}"><b-icon icon="basket3" class="ml-1"></b-icon> {{item.errorsVIPL.notQty}} </span>


      <span v-for="pr of item.pricesVIPL.slice(0,3)" :key="`${pr.qty}-${pr.price}`" title="цена(кол-во артикулов с этой ценой)">{{pr.price}}({{pr.qty}}), </span>
       <product-table-v4-price-for-model-widget :seller="'vipl'" :model="item.model" :prices="item.pricesVIPL" :key="'model-vipl-' + item.model" />

    </template>
    </template>

  </div>
</template>



<style scoped>
/*a {*/
/*  color: #111;*/
/*  font-weight: bold;*/
/*}*/
/*a.badge {*/
/*  color: #fff;*/
/*  font-weight: bold;*/
/*}*/


</style>

<script>
// import _ from "lodash"
// import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'

import ProductTableV4PriceForModelWidget from "@/ProductTableV4/components/PriceForModelWidget.vue";

export default {
  name: "global-products-cell-model",
  components: {ProductTableV4PriceForModelWidget},
  props: ['item','seller'],
  data() {
    return {
      isLoading: false,
role:''
    };
  },
  async mounted() {
    this.role = this.$cookies.get("dildo-role")
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    // this.sp=this.salePrice
    // this.komsawb=this.prWb
  },
  methods: {
    getBgClass: function(value) {
      if (value > 0) return 'bg-success'
      if (value <= 0) return 'bg-danger'
    }
  },
  computed: {
    // dohod: function () {
    //   return Math.ceil(this.sp - this.zakup - this.sp/100*this.komsawb - this.upak)
    // }
  },
};
</script>