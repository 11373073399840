<template>
  <b-row>
        <b-col class="pr-0">
          <a :href="'https://www.wildberries.ru/catalog/' + wbData.art_wb + '/detail.aspx?targetUrl=XS'" target="_blank">
            <span class="text-white pl-1 pr-1 mr-1" :class="getBgClass(wbData.rating)" title="рейтинг и кол-во отзывов">{{wbData.rating}} ({{wbData.feedbacks}})</span>
          </a>
<!--      <span class="text-white pl-1 pr-1 mr-1" :class="getBgClass(balances.spb+balances.m2+balances.m22+balances.spb_export)" title="Наш склад + Склад поставщика">спб: {{balances.spb+balances.m2+balances.m22}}, {{balances.spb_export}}</span>-->
<!--          <span class="text-white pl-1 pr-1 mr-1" :class="getBgClass(balances.msk_export+balances.msk)" title="Склад в москве + Склад поставщика в москве">мск: {{balances.msk}}, {{balances.msk_export}}</span>-->
        </b-col>

  </b-row>

</template>

<style>

</style>
<style scoped>
a {
  color: #111;
  font-weight: bold;
}
a.badge {
  color: #fff;
  font-weight: bold;
}
div {
  font-size: 12px;
}

</style>
<style>
input[type="number"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef!important;
  opacity: 1;
}
</style>

<script>
// import _ from "lodash"
// import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'

export default {
  name: "product-table-rating-widget",
  props: ['wbData'],
  data() {
    return {
      isLoading: false,

    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    // this.sp=this.salePrice
    // this.komsawb=this.prWb
  },
  methods: {
    getBgClass: function(value) {
      if (value > 3) return 'bg-success'
      if (value <= 3) return 'bg-danger'
    }
  },
  computed: {
    // dohod: function () {
    //   return Math.ceil(this.sp - this.zakup - this.sp/100*this.komsawb - this.upak)
    // }
  },
};
</script>