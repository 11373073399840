<template>
  <b-container fluid>
    <!-- Main table element -->
    <b-row align-h="between">
      <b-col cols="3">
        <b-list-group size="sm">
          <b-list-group-item class="d-flex justify-content-between align-items-center" title="">
            <span><b-icon icon="cash"></b-icon> Всего карточек </span><b-badge variant="danger" pill>{{stats.all || "-"}}</b-badge>
          </b-list-group-item>
          <b-list-group-item v-if="role=='glava'" class="d-flex justify-content-between align-items-center" title="Доход ниже 100руб, для цветных доход ниже 300руб">
            <span><b-icon icon="cash"></b-icon> Низкие цены </span><b-badge variant="danger" pill>{{stats.lowPrice || "-"}}</b-badge>
          </b-list-group-item>
          <b-list-group-item v-if="role=='glava'" class="d-flex justify-content-between align-items-center" title="Доход выше 650руб">
            <span><b-icon icon="calculator"></b-icon> Высокие цены </span><b-badge variant="danger" pill>{{stats.highPrice || "-"}} </b-badge>
          </b-list-group-item>
          <b-list-group-item v-if="role=='glava'" class="d-flex justify-content-between align-items-center" title="Есть у нас на складе или у поставщиков, но не транслируется на вб">
            <span><b-icon icon="basket3"></b-icon> Нет в продаже </span><b-badge variant="danger" pill>{{stats.notQty || "-"}} </b-badge>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between align-items-center" title="Рейтинг на wb < 3">
            <span><b-icon icon="basket3"></b-icon> Низкий рейтинг WB </span><b-badge variant="danger" pill>{{stats.lowRating || "-"}} </b-badge>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col cols="3">
<!--        <a href="#" @click="getNewPriceXlsx('ooo')">файл с новыми ценами для ооо</a><br>-->
<!--        <a href="#" @click="getNewPriceXlsx('ip')">файл с новыми ценами для ип</a><br>-->
<!--        <a href="#" @click="getNewPriceXlsx('vipl')">файл с новыми ценами для виплинзы</a><br>-->
      </b-col>
      <b-col cols="4"><span style="font-size: 10px">последнее обновление: {{lastUpdate}}</span></b-col>
    </b-row>

    <b-table
        :items="productsList"
        :fields="fields"
        :busy="isLoading"
        stacked="md"
        show-empty
        small
        headVariant="dark"
        fixed
        bordered
    >
<!--      <template #table-colgroup="row">-->
<!--        <col-->
<!--            v-for="field in row.fields"-->
<!--            :key="field.key"-->
<!--            :style="{ width: field.key === 'type' ? '211px' : '80px' }"-->
<!--        >-->
<!--      </template>-->
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Загрузка ...</strong>
        </div>
      </template>
      <template #cell(type)="row">
        <a size="sm" @click="row.toggleDetails" class="mr-2" href="#">
          {{row.item.type}}
        </a>
      </template>

      <template #cell(ooo)="{item}">
        <span title="Всего карточек на вб">{{item.stat.ooo}} </span>
        <span v-if="role=='glava'" title="С низкой ценой, доход ниже 100руб, для цветных доход ниже 300руб" :class="{'text-danger':item.errorsOOO.lowPrice>0, 'text-success':item.errorsOOO.lowPrice==0}"><b-icon icon="cash"></b-icon> {{item.errorsOOO.lowPrice}} </span>
        <span v-if="role=='glava'" title="С высокой ценой, доход выше 650руб" :class="{'text-danger':item.errorsOOO.highPrice>0, 'text-success':item.errorsOOO.highPrice==0}"><b-icon icon="calculator"></b-icon> {{item.errorsOOO.highPrice}} </span>
        <span v-if="role=='glava'" title="Не транслируются остатки" :class="{'text-danger':item.errorsOOO.notQty>0, 'text-success':item.errorsOOO.notQty==0}"><b-icon icon="basket3"></b-icon> {{item.errorsOOO.notQty}} </span>
        <span  title="Низкий рейтинг WB" :class="{'text-danger':item.errorsOOO.lowRating>0, 'text-success':item.errorsOOO.lowRating==0}"><b-icon icon="star-fill"></b-icon> {{item.errorsOOO.lowRating}} </span>
      </template>
      <template #cell(ip)="{item}">
        <span v-if="role=='glava'" title="Всего карточек на вб">{{item.stat.ip}} </span>
        <span v-if="role=='glava'" title="С низкой ценой, доход ниже 100руб, для цветных доход ниже 300руб" :class="{'text-danger':item.errorsIP.lowPrice>0, 'text-success':item.errorsIP.lowPrice==0}"><b-icon icon="cash"></b-icon> {{item.errorsIP.lowPrice}} </span>
        <span v-if="role=='glava'" title="С высокой ценой, доход выше 650руб" :class="{'text-danger':item.errorsIP.highPrice>0, 'text-success':item.errorsIP.highPrice==0}"><b-icon icon="calculator"></b-icon> {{item.errorsIP.highPrice}} </span>
        <span v-if="role=='glava'" title="Не транслируются остатки" :class="{'text-danger':item.errorsIP.notQty>0, 'text-success':item.errorsIP.notQty==0}"><b-icon icon="basket3"></b-icon> {{item.errorsIP.notQty}} </span>
        <span  title="Низкий рейтинг WB" :class="{'text-danger':item.errorsIP.lowRating>0, 'text-success':item.errorsIP.lowRating==0}"><b-icon icon="star-fill"></b-icon> {{item.errorsIP.lowRating}} </span>

      </template>
      <template #cell(vipl)="{item}">
        <span v-if="role=='glava'" title="Всего карточек на вб">{{item.stat.vipl}} </span>
        <span v-if="role=='glava'" title="С низкой ценой, доход ниже 100руб, для цветных доход ниже 300руб" :class="{'text-danger':item.errorsVIPL.lowPrice>0, 'text-success':item.errorsVIPL.lowPrice==0}"><b-icon icon="cash"></b-icon> {{item.errorsVIPL.lowPrice}} </span>
        <span v-if="role=='glava'" title="С высокой ценой, доход выше 650руб" :class="{'text-danger':item.errorsVIPL.highPrice>0, 'text-success':item.errorsVIPL.highPrice==0}"><b-icon icon="calculator"></b-icon> {{item.errorsVIPL.highPrice}} </span>
        <span v-if="role=='glava'" title="Не транслируются остатки" :class="{'text-danger':item.errorsVIPL.notQty>0, 'text-success':item.errorsVIPL.notQty==0}"><b-icon icon="basket3"></b-icon> {{item.errorsVIPL.notQty}} </span>
        <span  title="Низкий рейтинг WB" :class="{'text-danger':item.errorsVIPL.lowRating>0, 'text-success':item.errorsVIPL.lowRating==0}"><b-icon icon="star-fill"></b-icon> {{item.errorsVIPL.lowRating}} </span>
      </template>
      <template #row-details="{item}">
         <global-products-level1 :productsList="item.info" :lens-type="item.type" />

      </template>

    </b-table>

  </b-container>
</template>

<script>
import GlobalProductsLevel1 from "@/components3.0/Level1";
// import prod from "@/components3.0/globalTableApi"
import _ from "lodash";

export default {
  name: "global-products",
  data: () => ({
    isLoading: true,
    fields: [
      { key: 'type', label: 'Тип линз', sortable: false, sortDirection: 'desc' },
      { key: 'ooo', label: 'WB ooo', sortable: false, sortDirection: 'desc' },
      { key: 'ip', label: 'WB ip', sortable: false, sortDirection: 'desc' },
      { key: 'vipl', label: 'WB viplinza', sortable: false, sortDirection: 'desc' },
    ],
    productsList: [],
    lastUpdate: '',
    stats: {
      lowPrice: null,
      highPrice: null,
      notQty: null,
      lowRating:null,
      all: null
    },
    role:''
  }),
  methods: {
      calcDohod (salePrice, price, prWb, upak) {
    return Math.ceil(salePrice - price - salePrice/100*prWb - upak)
},
async getDataFromApi(filter) {
    const PRWB = 14 //комса вб в %
    const UPAK = 55 //стоимость упаковки в руб

    try {
        const data = await fetch(`${process.env.VUE_APP_SERVER_API}/globalProducts/newProd/${filter}`, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'dildo-token': window.$cookies.get("dildo-token")
            }
        }).then(resp=>resp.json())

        return {
            items: data.items.filter(e=>e.wbData)
                .map(el=>{
                    const dohod = this.calcDohod(el.wbData.price, el.price, PRWB, UPAK)
                    const errors = []
                    if (dohod<100) errors.push('Слишком низкие цены')
                    if (dohod<300 && el.type=="Цветные") errors.push('Слишком низкие цены')
                    if (dohod>650) errors.push('Слишком высокие цены')
                    if (el.wbData.qty==0 && (el.balances.m2+el.balances.m22+el.balances.msk+el.balances.msk_export+el.balances.spb+el.balances.spb_export)>0) errors.push('Не транслируются остатки')
                    if (el.wbData.rating<2 && el.wbData.rating>0) errors.push('Низкий рейтинг WB')

                  return {...el, dohod, errors}

                }),
            lastUpdate: data.lastUpdate
        }

    } catch (err) {
      this.isLoading = false
        return err
    }
},
    async getData() {
      this.isLoading = true
      const data = await this.getDataFromApi('low price')

      this.stats.lowPrice = data.items.filter(e=>e.errors.includes('Слишком низкие цены')).length
      this.stats.highPrice = data.items.filter(e=>e.errors.includes('Слишком высокие цены')).length
      this.stats.notQty = data.items.filter(e=>e.errors.includes('Не транслируются остатки')).length
      this.stats.lowRating = data.items.filter(e=>e.errors.includes('Низкий рейтинг WB')).length
      this.stats.all = data.items.length

      this.lastUpdate = data.lastUpdate
      this.productsList = _.chain(data.items)
          .groupBy("type")
          .map((value, key) => ({
            type: key,
            stat: {
              all: value.length,
              ooo: value.filter(e=>e.seller=='ooo').length,
              ip: value.filter(e=>e.seller=='ip').length,
              vipl: value.filter(e=>e.seller=='vipl').length,
            },
            errorsOOO: {
              lowPrice: value.filter(e=>e.seller=='ooo').filter(e=>e.errors.includes('Слишком низкие цены')).length,
              highPrice: value.filter(e=>e.seller=='ooo').filter(e=>e.errors.includes('Слишком высокие цены')).length,
              notQty: value.filter(e=>e.seller=='ooo').filter(e=>e.errors.includes('Не транслируются остатки')).length,
              lowRating: value.filter(e=>e.seller=='ooo').filter(e=>e.errors.includes('Низкий рейтинг WB')).length,
            },
            errorsIP: {
              lowPrice: value.filter(e=>e.seller=='ip').filter(e=>e.errors.includes('Слишком низкие цены')).length,
              highPrice: value.filter(e=>e.seller=='ip').filter(e=>e.errors.includes('Слишком высокие цены')).length,
              notQty: value.filter(e=>e.seller=='ip').filter(e=>e.errors.includes('Не транслируются остатки')).length,
              lowRating: value.filter(e=>e.seller=='ip').filter(e=>e.errors.includes('Низкий рейтинг WB')).length,
            },
            errorsVIPL: {
              lowPrice: value.filter(e=>e.seller=='vipl').filter(e=>e.errors.includes('Слишком низкие цены')).length,
              highPrice: value.filter(e=>e.seller=='vipl').filter(e=>e.errors.includes('Слишком высокие цены')).length,
              notQty: value.filter(e=>e.seller=='vipl').filter(e=>e.errors.includes('Не транслируются остатки')).length,
              lowRating: value.filter(e=>e.seller=='vipl').filter(e=>e.errors.includes('Низкий рейтинг WB')).length,
            },
            info: _.chain(value)
                .groupBy("model")
                .map((valu, ke) => ({
                  model: ke,
                  stat: {
                    all: valu.length,
                    ooo: valu.filter(e=>e.seller=='ooo').length,
                    ip: valu.filter(e=>e.seller=='ip').length,
                    vipl: valu.filter(e=>e.seller=='vipl').length,
                  },
                  errorsOOO: {
                    lowPrice: valu.filter(e=>e.seller=='ooo').filter(e=>e.errors.includes('Слишком низкие цены')).length,
                    highPrice: valu.filter(e=>e.seller=='ooo').filter(e=>e.errors.includes('Слишком высокие цены')).length,
                    notQty: valu.filter(e=>e.seller=='ooo').filter(e=>e.errors.includes('Не транслируются остатки')).length,
                    lowRating: valu.filter(e=>e.seller=='ooo').filter(e=>e.errors.includes('Низкий рейтинг WB')).length,
                  },
                  errorsIP: {
                    lowPrice: valu.filter(e=>e.seller=='ip').filter(e=>e.errors.includes('Слишком низкие цены')).length,
                    highPrice: valu.filter(e=>e.seller=='ip').filter(e=>e.errors.includes('Слишком высокие цены')).length,
                    notQty: valu.filter(e=>e.seller=='ip').filter(e=>e.errors.includes('Не транслируются остатки')).length,
                    lowRating: valu.filter(e=>e.seller=='ip').filter(e=>e.errors.includes('Низкий рейтинг WB')).length,
                  },
                  errorsVIPL: {
                    lowPrice: valu.filter(e=>e.seller=='vipl').filter(e=>e.errors.includes('Слишком низкие цены')).length,
                    highPrice: valu.filter(e=>e.seller=='vipl').filter(e=>e.errors.includes('Слишком высокие цены')).length,
                    notQty: valu.filter(e=>e.seller=='vipl').filter(e=>e.errors.includes('Не транслируются остатки')).length,
                    lowRating: valu.filter(e=>e.seller=='vipl').filter(e=>e.errors.includes('Низкий рейтинг WB')).length,
                  },
                  // prices: valu.map(e=>e.wbData.price),
                  pricesOOO: _.orderBy(_.chain(valu.filter(e=>e.seller=='ooo').map(e=>({price:e.wbData.price})))
                      .groupBy("price")
                      .map((val,k)=>({qty:val.length,price:k}))
                      .value(),"qty","desc")
                  ,
                  pricesIP: _.orderBy(_.chain(valu.filter(e=>e.seller=='ip').map(e=>({price:e.wbData.price})))
                      .groupBy("price")
                      .map((val,k)=>({qty:val.length,price:k}))
                      .value(),"qty","desc")
                  ,
                  pricesVIPL: _.orderBy(_.chain(valu.filter(e=>e.seller=='vipl').map(e=>({price:e.wbData.price})))
                      .groupBy("price")
                      .map((val,k)=>({qty:val.length,price:k}))
                      .value(),"qty","desc")
                  ,
                  info: valu
                }))
                .value()
          }))
          .value()
      // console.log(this.productsList[0])

          this.isLoading = false

      // }
    },

  },
  components: {GlobalProductsLevel1},
  async mounted() {
    this.role = this.$cookies.get("dildo-role")

    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});
    if (dildoToken) {
      await this.getData()
    }


  }
};
</script>

<style>
td {
  font-size: 10px;
}
th div {
  font-size: 10px;
}
.text-muted {
  color: #6c757d6e !important;
}
.list-group-item {
  padding: 0.2rem 0.5rem;
}
</style>
