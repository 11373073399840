<template>
  <b-container fluid>
    <b-row>
      <b-col >
        <div>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>
        </div>
      </b-col>
      <b-col v-if="role=='glava'">
        <b-form-checkbox id="checkbox-error1" v-model="filters.isErrorLowPrice" name="checkbox-error1">
          С низкой ценой
        </b-form-checkbox>
      </b-col>
      <b-col v-if="role=='glava'">
        <b-form-checkbox id="checkbox-error2" v-model="filters.isErrorHighPrice" name="checkbox-error2">
          С высокой ценой
        </b-form-checkbox>
      </b-col>
      <b-col v-if="role=='glava'">
        <b-form-checkbox id="checkbox-error3" v-model="filters.isErrorNotQty" name="checkbox-error3">
          Нет в продаже
        </b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-checkbox id="checkbox-error4" v-model="filters.isErrorLowRating" name="checkbox-error4">
          Низкий рейтинг
        </b-form-checkbox>
      </b-col>
<!--      <b-col>-->
<!--        <b-form-checkbox-->
<!--            id="checkbox-promo"-->
<!--            v-model="filters.isPromoPrice"-->
<!--            name="checkbox-promo"-->
<!--        >С ценой для акции</b-form-checkbox>-->
<!--      </b-col>-->
    </b-row>
    <b-row>
      <b-col>
        <b-form-group v-slot="{ ariaDescribedby }" v-if="search.allRadius.length>1">
          <b-form-checkbox-group
              v-model="search.selectedRadius"
              :options="search.allRadius"
              :aria-describedby="ariaDescribedby"
              name="buttons-1"
              buttons
              size="sm"
              class="search-params"
          ></b-form-checkbox-group>
        </b-form-group>
        <b-form-group v-slot="{ ariaDescribedby }" v-if="search.allCyl.length>1">
          <b-form-checkbox-group
              v-model="search.selectedCyl"
              :options="search.allCyl"
              :aria-describedby="ariaDescribedby"
              name="buttons-1"
              buttons
              size="sm"
              class="search-params"
          ></b-form-checkbox-group>
        </b-form-group>
        <b-form-group v-slot="{ ariaDescribedby }" v-if="search.allAx.length>1">
          <b-form-checkbox-group
              v-model="search.selectedAx"
              :options="search.allAx"
              :aria-describedby="ariaDescribedby"
              name="buttons-1"
              buttons
              size="sm"
              class="search-params"
          ></b-form-checkbox-group>
        </b-form-group>
        <b-form-group v-slot="{ ariaDescribedby }" v-if="search.allAdida.length>1">
          <b-form-checkbox-group
              v-model="search.selectedAdida"
              :options="search.allAdida"
              :aria-describedby="ariaDescribedby"
              name="buttons-1"
              buttons
              size="sm"
              class="search-params"
          ></b-form-checkbox-group>
        </b-form-group>
        <b-form-group v-slot="{ ariaDescribedby }" v-if="search.allColor.length>1">
          <b-form-checkbox-group
              v-model="search.selectedColor"
              :options="search.allColor"
              text-field="test"
              :aria-describedby="ariaDescribedby"
              name="buttons-1"
              buttons
              size="sm"
              class="search-params"
          ></b-form-checkbox-group>
        </b-form-group>
        <b-form-group v-slot="{ ariaDescribedby }" v-if="search.allDio.length>1 && search.allColor.length>1">
          <b-form-checkbox-group
              v-model="search.selectedDio"
              :options="search.allDio"
              text-field="test"
              :aria-describedby="ariaDescribedby"
              name="buttons-1"
              buttons
              size="sm"
              class="search-params"
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
    <b-table
        :items="productList"
        :fields="fields"
        :per-page="perPage"
        :current-page="curPage"
        show-empty
        small
        headVariant="dark"
        sticky-header
        bordered
        hover
    >
<!--      <template #table-colgroup="row">-->
<!--        <col-->
<!--            v-for="field in row.fields"-->
<!--            :key="field.key"-->
<!--            :style="{ width: field.key === 'key' ? '170px' : '85px' }"-->
<!--        >-->
<!--      </template>-->
      <template #cell(attr)="{item}">
<span style="font-size: 14px">{{item.name}} {{item.attr}}</span><br>
        <u>{{item.errors.join(", ")}}</u>
<!--        <a :href="'https://www.wildberries.ru/catalog/' + item.art_wb + '/detail.aspx?targetUrl=XS'" target="_blank">{{item.seller}} {{item.attr}}</a>-->

      </template>
      <template #cell(wb_ooo)="{item}">
        <template v-if="item.ooo.length>0">
          <div v-for="art_wb_item of item.ooo" :key="art_wb_item.art_wb" class="mt-1 mb-1">
            <product-table-sklad-widget
                :balances="art_wb_item.balances"
                :wb-data="art_wb_item.wbData"
                v-if="role=='glava'"
            />
            <product-table-price-widget
                :sale-price="art_wb_item.wbData.price"
                :pr-wb="17"
                :upak="55"
                :zakup="art_wb_item.price"
                style="max-width: 350px;"
                v-if="role=='glava'"
            />
            <product-table-rating-widget
                :wb-data="art_wb_item.wbData"
            />

          </div>
        </template>
        <template v-else>нет артикулов</template>

      </template>
      <template #cell(wb_ip)="{item}">
        <template v-if="item.ip.length>0">
        <div v-for="art_wb_item of item.ip" :key="art_wb_item.art_wb" class="mb-2">
          <product-table-sklad-widget :balances="art_wb_item.balances" :wb-data="art_wb_item.wbData" v-if="role=='glava'" />
          <product-table-price-widget
              :sale-price="art_wb_item.wbData.price"
              :pr-wb="17"
              :upak="55"
              :zakup="art_wb_item.price"
              style="max-width: 350px;"
              v-if="role=='glava'"
          />
          <product-table-rating-widget
              :wb-data="art_wb_item.wbData"
          />
        </div>
      </template>
      <template v-else>нет артикулов</template>
      </template>
      <template #cell(wb_vipl)="{item}">
        <template v-if="item.vipl.length>0">
        <div v-for="art_wb_item of item.vipl" :key="art_wb_item.art_wb">
          <product-table-sklad-widget :balances="art_wb_item.balances" :wb-data="art_wb_item.wbData" v-if="role=='glava'" />
          <product-table-price-widget
              :sale-price="art_wb_item.wbData.price"
              :pr-wb="17"
              :upak="55"
              :zakup="art_wb_item.price"
              style="max-width: 350px;"
              v-if="role=='glava'"
          />
          <product-table-rating-widget
              :wb-data="art_wb_item.wbData"
          />
        </div>
      </template>
<template v-else>нет артикулов</template>

      </template>


    </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col >
        <div>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          > </b-pagination>
        </div>
      </b-col>

    </b-row>

  </b-container>
</template>
<style>
.b-table-sticky-header {
   overflow-y: inherit;
  max-height: 10000px;
}
label[for="checkbox-promo"] {
  font-size: 16px;
}
label[for="checkbox-error"] {
  font-size: 16px;
}

.search-params .btn-secondary {
  background-color: #fff;
  color: #000;
  padding: 0.2rem 0.3rem;
  font-size: 10px;
  line-height: 1.2;
  border-radius: 0.1rem;
}
.form-group {
  margin-bottom: 0.1rem;
}
</style>
<script>
import _ from "lodash"


import ProductTableSkladWidget from "@/components3.0/SkladWidget.vue";
import ProductTablePriceWidget from "@/components3.0/PriceWidget.vue";
import ProductTableRatingWidget from "@/components3.0/RatingWidget.vue";

export default {
  name: "global-products-level3",
  components: {ProductTableRatingWidget, ProductTablePriceWidget, ProductTableSkladWidget},
  props: ["productsList", "isLog"],
  data: () => ({
    isLoading: false,
    fields: [
      { key: 'attr', label: 'Линзы', sortable: true, sortDirection: 'desc' },
      { key: 'wb_ooo', label: 'WB ооо', sortable: false, sortDirection: 'desc' },
      { key: 'wb_ip', label: 'WB ip', sortable: false, sortDirection: 'desc' },
      { key: 'wb_vipl', label: 'WB viplinza', sortable: false, sortDirection: 'desc' },
    ],
    curPage: 1,
    totalRows: 100,
    perPage: 30,
    filters: {
      isPromoPrice:false,
      isErrorLowPrice: false,
      isErrorHighPrice: false,
      isErrorNotQty: false,
      isErrorLowRating: false,
    },
    sellers:[],
    search: {
      allDio: [],
      allRadius:[],
      allCyl:[],
      allAx:[],
      allAdida:[],
      allColor:[],
      selectedRadius: [],
      selectedCyl: [],
      selectedAx: [],
      selectedAdida: [],
      selectedColor: [],
      selectedDio: [],
    },
    role:''
  }),
  methods: {
    getBgClass: function(value) {
      if (value > 0) return 'bg-success'
      if (value <= 0) return 'bg-danger'
    },

  },
  // components: {OstatiOriginalBarcode, BarcodeInfo},
  async mounted() {
    this.role = this.$cookies.get("dildo-role")

    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    this.totalRows = this.productList.length
    // this.sellers=[...new Set(this.items.map(e=>e.seller))]
    this.search.allDio=[...new Set(this.productsList.map(e=>e.dio))]
    this.search.allRadius=[...new Set(this.productsList.map(e=>e.radius))]
    this.search.allCyl=[...new Set(this.productsList.map(e=>e.cyl))]
    this.search.allAx=[...new Set(this.productsList.map(e=>e.ax))]
    this.search.allAdida=[...new Set(this.productsList.map(e=>e.adida))]
    this.search.allColor=[...new Set(this.productsList.map(e=>e.color))]

  },
  computed: {
    productList: function () {
      let result = _.chain(this.productsList)
          .groupBy("attr")
          .map((value, key) => {
            let rowVariant = ''
            const errors=[]
            // if (value.filter(e=>e.errors.includes('Слишком низкие цены')).length) {
            //   rowVariant = 'warning'
            //   errors.push('Слишком низкие цены')
            // }
            // if (value.filter(e=>e.errors.includes('Слишком высокие цены')).length) {
            //   rowVariant = 'info'
            //   errors.push('Слишком высокие цены')
            // }
            // if (value.filter(e=>e.errors.includes('Не транслируются остатки')).length > 0) {
            //   rowVariant = 'danger'
            //   errors.push('Не транслируются остатки')
            // }
            if (value.filter(e=>e.errors.includes('Низкий рейтинг WB')).length > 0) {
              rowVariant = 'danger'
              errors.push('Низкий рейтинг WB')
            }
            return {
              attr: key,
              name: value[0].model,
              radius:value[0].radius,
              ax:value[0].ax,
              cyl:value[0].cyl,
              adida:value[0].adida,
              color:value[0].color,
              ooo: value.filter(e=>e.seller=='ooo'),
              ip: value.filter(e=>e.seller=='ip'),
              vipl: value.filter(e=>e.seller=='vipl'),
              _rowVariant: rowVariant,
              errors: errors
            }
          })
          .value()

      // : false,
      //     : false,
      //     : false,

      if (this.filters.isErrorLowPrice) result = result.filter(e=>e.errors.includes('Слишком низкие цены'))
      if (this.filters.isErrorHighPrice) result = result.filter(e=>e.errors.includes('Слишком высокие цены'))
      if (this.filters.isErrorNotQty) result = result.filter(e=>e.errors.includes('Не транслируются остатки'))
      if (this.filters.isErrorLowRating) result = result.filter(e=>e.errors.includes('Низкий рейтинг WB'))

      if (this.search.selectedRadius.length>0) result = result.filter(e=>this.search.selectedRadius.includes(e.radius))
      if (this.search.selectedCyl.length>0) result = result.filter(e=>this.search.selectedCyl.includes(e.cyl))
      if (this.search.selectedAx.length>0) result = result.filter(e=>this.search.selectedAx.includes(e.ax))
      if (this.search.selectedAdida.length>0) result = result.filter(e=>this.search.selectedAdida.includes(e.adida))
      if (this.search.selectedColor.length>0) result = result.filter(e=>this.search.selectedColor.includes(e.color))
      if (this.search.selectedDio.length>0) result = result.filter(e=>this.search.selectedDio.includes(e.dio))

      return result
    }
  },
  watch: {
    productList: function () {
      // console.log('reload')
      this.totalRows = this.productList.length
    },
  }
};
</script>