<template>
  <span>
    <span>
    <a href="#" :id="`popover-${seller}-${model}`" title="Все цены"><b><span v-for="pr of prices.slice(0,1)" :key="`${pr.qty}-${pr.price}`" title="наша цена(кол-во артикулов с этой ценой)">{{pr.price}} ({{pr.qty}})</span></b>  </a>
    <b-popover :target="`popover-${seller}-${model}`" title="Все цены" triggers="focus">
      <input type="text" v-model="newSalePrice">
      <a href="#" @click="getNewPriceXlsx(seller,model,newSalePrice)" title="Скачать эксель с новыми ценами">
        <b-icon icon="file-earmark-excel" style="width: 15px; height: 15px;"></b-icon>
      </a>
      <b-table-simple hover small caption-top responsive>
        <b-tbody>
          <b-tr v-for="p of prices" :key="p.qty+'--'+p.prices">
            <b-td>{{ p.qty }}</b-td>
            <b-td>{{ p.price }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-popover>
  </span>
 |
    <span>
<!--    <a href="#" :id="`popover-${seller}-${model}-conk`">-->
<!--      <span title="Популярная цена у конкурентов">{{conk.mostPopular.salePriceU}} ({{conk.mostPopular.qty}})</span>,-->
<!--      <span title="Минимальная цена у конкурентов">{{conk.min.salePriceU}}</span>-->
<!--      ... <span title="Максимальная цена у конкурентов">{{conk.max.salePriceU}}</span>-->

<!--    </a>-->
<!--    <b-popover :target="`popover-${seller}-${model}-conk`" title="Все цены" triggers="focus">-->
<!--      <input type="text" v-model="newSalePrice">-->
<!--      <a href="#" @click="getNewPriceXlsx(seller,model,newSalePrice)" title="Скачать эксель с новыми ценами">-->
<!--        <b-icon icon="file-earmark-excel" style="width: 15px; height: 15px;"></b-icon>-->
<!--      </a>-->

<!--      <b-table-simple hover small caption-top responsive>-->
<!--        <b-tbody>-->
<!--          <b-tr v-for="p of prices" :key="p.qty+'&#45;&#45;'+p.prices">-->
<!--            <b-td>{{ p.qty }}</b-td>-->
<!--            <b-td>{{ p.price }}</b-td>-->
<!--          </b-tr>-->
<!--        </b-tbody>-->
<!--      </b-table-simple>-->
<!--    </b-popover>-->
  </span>
  </span>


</template>

<style>

</style>
<style scoped>
a {
  color: #111;
  /*font-weight: bold;*/
}
a.badge {
  color: #fff;
  /*font-weight: bold;*/
}
div {
  font-size: 12px;
}

</style>
<style>
input[type="number"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef!important;
  opacity: 1;
}
</style>

<script>
// import _ from "lodash"
// import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'

export default {
  name: "product-table-v4-price-for-model-widget",
  props: ['model','seller','prices'],
  data() {
    return {
      newSalePrice: null,
      conk:{}

    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});
// this.getConk(this.model)

  },
  methods: {
    async getNewPriceXlsx(seller, model, newSalePrice) {
      window.location = `${process.env.VUE_APP_SERVER_API}/globalProducts/setNewPriceForModel/${model}/${seller}/${newSalePrice}/new-price-${seller}-${model}`
    },
    async getConk(model) {
      const conkData = await fetch(`${process.env.VUE_APP_SERVER_API}/globalProducts/conkPriceForModel/${model}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'dildo-token': window.$cookies.get("dildo-token")
        }
      }).then(resp=>resp.json())
      this.conk = conkData.items
      console.log(conkData.items)
    }
  },
  computed: {
  },
};
</script>