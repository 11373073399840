<template>
  <b-container fluid>
    <!-- Main table element -->
    <b-table
        :items="productsList"
        :fields="fields"
        stacked="md"
        show-empty
        small
        headVariant="dark"
        fixed
        bordered
    >
<!--      <template #table-colgroup="row">-->
<!--        <col-->
<!--            v-for="field in row.fields"-->
<!--            :key="field.key"-->
<!--            :style="{ width: field.key === 'modelName' ? '200px' : '80px' }"-->
<!--        >-->
<!--      </template>-->
      <template #cell(model)="row">
        <div class="mt-1"><span class="text-primary" style="cursor: pointer" @click="row.toggleDetails">{{row.item.model.slice(0,35)}}</span></div>
      </template>
      <template #cell(ooo)="{item}">
           <global-products-cell-model :item="item" :seller="'ooo'" />
      </template>
      <template #cell(ip)="{item}">
        <global-products-cell-model :item="item" :seller="'ip'" />
<!--        | <a href="#" @click="getXlsx(item.model, 'ip')" title="Скачать эксель с ценами"><b-icon icon="file-earmark-excel"></b-icon></a>-->
      </template>
      <template #cell(vipl)="{item}">
        <global-products-cell-model :item="item" :seller="'vipl'" />
<!--        | <a href="#" @click="getXlsx(item.model, 'vipl')" title="Скачать эксель с ценами"><b-icon icon="file-earmark-excel"></b-icon></a>-->
      </template>


      <template #row-details="{item}">
<!--        <global-products-level2 :products-list="item.info" :lens-type="lensType" :is-log="item.isLog" />-->
        <global-products-level3 :products-list="item.info" />
      </template>

    </b-table>

  </b-container>
</template>
<style>
.custom-control-label div {
  font-size: 12px;
  margin-top: 2px;
}

.table-sm th, .table-sm td {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-left: .3rem;
}
</style>
<script>
import GlobalProductsLevel3 from "@/components3.0/Level3.vue";

import GlobalProductsCellModel from "@/ProductTableV4/cell/CellModel.vue";
export default {
  name: "global-products-level1",
  props: ["productsList", "lensType"],
  data: () => ({
    isLoading: false,
    fields: [
      { key: 'model', label: 'Модель линз', sortable: true, sortDirection: 'desc' },
      { key: 'ooo', label: 'WB ooo', sortable: false, sortDirection: 'desc' },
      { key: 'ip', label: 'WB ip', sortable: false, sortDirection: 'desc' },
      { key: 'vipl', label: 'WB viplinza', sortable: false, sortDirection: 'desc' },
    ],
    items: []
  }),
  methods: {
    async getXlsx(model, seller) {
      window.location = `${process.env.VUE_APP_SERVER_API}/globalProducts/export-xlsx/${model}/${seller}/${seller}_${model}`
    },

  },
  components: {GlobalProductsCellModel, GlobalProductsLevel3},
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    // this.items = this.productsList
    // console.log(this.productsList)
  }
};
</script>