var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('div',[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"my-table","limit":"15"},model:{value:(_vm.curPage),callback:function ($$v) {_vm.curPage=$$v},expression:"curPage"}})],1)]),(_vm.role=='glava')?_c('b-col',[_c('b-form-checkbox',{attrs:{"id":"checkbox-error1","name":"checkbox-error1"},model:{value:(_vm.filters.isErrorLowPrice),callback:function ($$v) {_vm.$set(_vm.filters, "isErrorLowPrice", $$v)},expression:"filters.isErrorLowPrice"}},[_vm._v(" С низкой ценой ")])],1):_vm._e(),(_vm.role=='glava')?_c('b-col',[_c('b-form-checkbox',{attrs:{"id":"checkbox-error2","name":"checkbox-error2"},model:{value:(_vm.filters.isErrorHighPrice),callback:function ($$v) {_vm.$set(_vm.filters, "isErrorHighPrice", $$v)},expression:"filters.isErrorHighPrice"}},[_vm._v(" С высокой ценой ")])],1):_vm._e(),(_vm.role=='glava')?_c('b-col',[_c('b-form-checkbox',{attrs:{"id":"checkbox-error3","name":"checkbox-error3"},model:{value:(_vm.filters.isErrorNotQty),callback:function ($$v) {_vm.$set(_vm.filters, "isErrorNotQty", $$v)},expression:"filters.isErrorNotQty"}},[_vm._v(" Нет в продаже ")])],1):_vm._e(),_c('b-col',[_c('b-form-checkbox',{attrs:{"id":"checkbox-error4","name":"checkbox-error4"},model:{value:(_vm.filters.isErrorLowRating),callback:function ($$v) {_vm.$set(_vm.filters, "isErrorLowRating", $$v)},expression:"filters.isErrorLowRating"}},[_vm._v(" Низкий рейтинг ")])],1)],1),_c('b-row',[_c('b-col',[(_vm.search.allRadius.length>1)?_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"search-params",attrs:{"options":_vm.search.allRadius,"aria-describedby":ariaDescribedby,"name":"buttons-1","buttons":"","size":"sm"},model:{value:(_vm.search.selectedRadius),callback:function ($$v) {_vm.$set(_vm.search, "selectedRadius", $$v)},expression:"search.selectedRadius"}})]}}],null,false,1831109899)}):_vm._e(),(_vm.search.allCyl.length>1)?_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"search-params",attrs:{"options":_vm.search.allCyl,"aria-describedby":ariaDescribedby,"name":"buttons-1","buttons":"","size":"sm"},model:{value:(_vm.search.selectedCyl),callback:function ($$v) {_vm.$set(_vm.search, "selectedCyl", $$v)},expression:"search.selectedCyl"}})]}}],null,false,4130998123)}):_vm._e(),(_vm.search.allAx.length>1)?_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"search-params",attrs:{"options":_vm.search.allAx,"aria-describedby":ariaDescribedby,"name":"buttons-1","buttons":"","size":"sm"},model:{value:(_vm.search.selectedAx),callback:function ($$v) {_vm.$set(_vm.search, "selectedAx", $$v)},expression:"search.selectedAx"}})]}}],null,false,2453698571)}):_vm._e(),(_vm.search.allAdida.length>1)?_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"search-params",attrs:{"options":_vm.search.allAdida,"aria-describedby":ariaDescribedby,"name":"buttons-1","buttons":"","size":"sm"},model:{value:(_vm.search.selectedAdida),callback:function ($$v) {_vm.$set(_vm.search, "selectedAdida", $$v)},expression:"search.selectedAdida"}})]}}],null,false,4287765995)}):_vm._e(),(_vm.search.allColor.length>1)?_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"search-params",attrs:{"options":_vm.search.allColor,"text-field":"test","aria-describedby":ariaDescribedby,"name":"buttons-1","buttons":"","size":"sm"},model:{value:(_vm.search.selectedColor),callback:function ($$v) {_vm.$set(_vm.search, "selectedColor", $$v)},expression:"search.selectedColor"}})]}}],null,false,2699692569)}):_vm._e(),(_vm.search.allDio.length>1 && _vm.search.allColor.length>1)?_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"search-params",attrs:{"options":_vm.search.allDio,"text-field":"test","aria-describedby":ariaDescribedby,"name":"buttons-1","buttons":"","size":"sm"},model:{value:(_vm.search.selectedDio),callback:function ($$v) {_vm.$set(_vm.search, "selectedDio", $$v)},expression:"search.selectedDio"}})]}}],null,false,1935511769)}):_vm._e()],1)],1),_c('b-row',[_c('b-col',[_c('b-table',{attrs:{"items":_vm.productList,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.curPage,"show-empty":"","small":"","headVariant":"dark","sticky-header":"","bordered":"","hover":""},scopedSlots:_vm._u([{key:"cell(attr)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(item.name)+" "+_vm._s(item.attr))]),_c('br'),_c('u',[_vm._v(_vm._s(item.errors.join(", ")))])]}},{key:"cell(wb_ooo)",fn:function(ref){
var item = ref.item;
return [(item.ooo.length>0)?_vm._l((item.ooo),function(art_wb_item){return _c('div',{key:art_wb_item.art_wb,staticClass:"mt-1 mb-1"},[(_vm.role=='glava')?_c('product-table-sklad-widget',{attrs:{"balances":art_wb_item.balances,"wb-data":art_wb_item.wbData}}):_vm._e(),(_vm.role=='glava')?_c('product-table-price-widget',{staticStyle:{"max-width":"350px"},attrs:{"sale-price":art_wb_item.wbData.price,"pr-wb":17,"upak":55,"zakup":art_wb_item.price}}):_vm._e(),_c('product-table-rating-widget',{attrs:{"wb-data":art_wb_item.wbData}})],1)}):[_vm._v("нет артикулов")]]}},{key:"cell(wb_ip)",fn:function(ref){
var item = ref.item;
return [(item.ip.length>0)?_vm._l((item.ip),function(art_wb_item){return _c('div',{key:art_wb_item.art_wb,staticClass:"mb-2"},[(_vm.role=='glava')?_c('product-table-sklad-widget',{attrs:{"balances":art_wb_item.balances,"wb-data":art_wb_item.wbData}}):_vm._e(),(_vm.role=='glava')?_c('product-table-price-widget',{staticStyle:{"max-width":"350px"},attrs:{"sale-price":art_wb_item.wbData.price,"pr-wb":17,"upak":55,"zakup":art_wb_item.price}}):_vm._e(),_c('product-table-rating-widget',{attrs:{"wb-data":art_wb_item.wbData}})],1)}):[_vm._v("нет артикулов")]]}},{key:"cell(wb_vipl)",fn:function(ref){
var item = ref.item;
return [(item.vipl.length>0)?_vm._l((item.vipl),function(art_wb_item){return _c('div',{key:art_wb_item.art_wb},[(_vm.role=='glava')?_c('product-table-sklad-widget',{attrs:{"balances":art_wb_item.balances,"wb-data":art_wb_item.wbData}}):_vm._e(),(_vm.role=='glava')?_c('product-table-price-widget',{staticStyle:{"max-width":"350px"},attrs:{"sale-price":art_wb_item.wbData.price,"pr-wb":17,"upak":55,"zakup":art_wb_item.price}}):_vm._e(),_c('product-table-rating-widget',{attrs:{"wb-data":art_wb_item.wbData}})],1)}):[_vm._v("нет артикулов")]]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('div',[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"my-table","limit":"15"},model:{value:(_vm.curPage),callback:function ($$v) {_vm.curPage=$$v},expression:"curPage"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }